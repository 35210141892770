import React, { Component } from "react";
import {common} from 'react_library';
import { LoadPanel } from 'devextreme-react/load-panel';

class Loader extends Component {
    render(){
      return (
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{ of: '.main-content' }}
                onHiding={common.loader.hideLoadPanel.bind(this)}
                visible={this.props.visible}
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={false}
            />
        );
    } 
    
}


export default Loader;
import React, { Component } from "react";

import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  HeaderFilter,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  //SearchPanel,
  FilterRow,
  StateStoring,
  Format
} from 'devextreme-react/data-grid';

import { NavLink } from "react-router-dom";
import {common} from 'react_library';
import Loader from './Loader';


/**
 * Componente per la gestione della griglia.
 * Di seguito le proprietà per la composizione:
 *    <DxGrid 
        data={default:[DATI], columns:[COLONNE]}          Data della griglia compilata con DATI E COLONNE
        data-key="uuid"                                   Campo chiave del record sulla griglia
        gridUpdate = {this.handleToUpdate.bind(this)}     Metodo per l'aggiornamento della griglia
        delete={{permission:"account.deleteAccess"}}      Permesso di cancellazione; se compilato visualizza l'icona di cancellazione
        update={{permission: "account.updateAccess",      Permesso di update; se compilato visualizza l'iconda di update e rimanda al path indicato
                pathname:"/portal/account-page",
                search: `?parent=${common.session.fn_getUserUuid()}&uuid=#KEY#`}}>                                
      </DxGrid>    

      PROPRIETA' OGGETTO COLONNE: 
          field: IMPOSTA IL VALORE DEL CAMPO E LA CHIAVE DELLA COLONNA
          text: IMPOSTA IL TESTO DELL'HEADER
          type: IMPOSTA LA TIPOLOGIA DELLA COLONNA
          allowHeaderFiltering: IMPOSTA SE LA COLONNA E' FILTRABILE O MENO
          width: LARGHEZZA COLONNA

 */


class DxGrid extends Component {
    dataSource= [];
    columns=[];
    delete=false;
    permissionDelete="";
    dataKey="";

    update=false;
    print=false;
    permissionUpdate="";
    pathUpdate="";
    searchUpdate="";

    constructor(props) {
      super(props);
                   
      this.dataSource=this.props.data["default"];
      this.columns=this.props.data["columns"];     
      this.prints=this.props.data["print"];

      this.dataKey=this.props["data-key"];
      this.gridUpdate= this.props["gridUpdate"];

      if(this.props["delete"]!==undefined){        
        this.permissionDelete=this.props["delete"]["permission"];
        if(common.service.checkFunctionality(this.permissionDelete)){
          this.delete=true;
        }
      }

      if(this.props["update"]!==undefined){        
        this.permissionUpdate=this.props["update"]["permission"];
        if(common.service.checkFunctionality(this.permissionUpdate)){
          this.update=true;
        }
        
        this.pathUpdate= this.props["update"]["pathname"];
        this.searchUpdate=this.props["update"]["search"];
      }

      if(this.prints!==undefined){
        this.permissionPrint=this.props["print"]["permission"];
        if(common.service.checkFunctionality(this.permissionPrint)){
          this.print=true;
        }
      }

      this.state = {loadPanelVisible:false};   

    }
   

    render(){        
      /*
      <SearchPanel visible={true}
                  width={240}
                  placeholder="Search..." />
      */
      return (
        <div className="dxGrid">
          <Loader visible={this.state.loadPanelVisible}></Loader>        
          <DataGrid
            dataSource={this.props.data["default"]}
            allowColumnReordering={this.props.allowColumnReordering}
            showBorders={true}
            //onContentReady={this.onContentReady}
          >
            
            <FilterRow visible={true} applyFilter='auto'/>

            <HeaderFilter visible={this.props.headerFilterVisible} />

            <GroupPanel visible={this.props.groupPanelVisible} />
            
            <Grouping autoExpandAll={false} />

            {this.delete ?
              <Column key="delete" caption="" width={35}  allowSorting={false}
              cellRender={cellRenderDelete} clickFunction={this.handleDelete.bind(this)} dataKey={this.dataKey}/>                
              :
                ''
            }

            {this.print ?
              <Column key="print" caption="" width={35}  allowSorting={false}
              cellRender={cellRenderPrint} clickFunction={this.handlePrint.bind(this)} dataKey={this.dataKey} dataPrints={this.dataPrints}/>                
              :
                ''
            }

            {this.update ?
              <Column key="update" caption="" width={35}  allowSorting={false}
              cellRender={cellRenderUpdate} pathUpdate={this.pathUpdate} searchUpdate={this.searchUpdate} dataKey={this.dataKey}/>                
              :
                ''
            }

            {this.columns.map(column =>                                                              
                <Column 
                  key={(column.field.toLowerCase().indexOf(" as ") > -1) ? column.field.substring(column.field.toLowerCase().lastIndexOf(" as ") + 4, column.field.length) : column.field} 
                  caption={column.text} 
                  dataField={(column.field.toLowerCase().indexOf(" as ") > -1) ? column.field.substring(column.field.toLowerCase().lastIndexOf(" as ") + 4, column.field.length) : column.field} 
                  dataType={(column.type==="decimal") ? "number" : (column.type==="datetime" || column.type==="date") ? column.type : ''} 
                  allowHeaderFiltering={column.allowHeaderFiltering} 
                  allowSearch={true} 
                  width={column.width}  
                  //format={(column.type==="datetime") ? "dd/MM/yyyy HH:mm:ss" : (column.type==="date") ? "dd/MM/yyyy" :  ""}                
                  customizeText={(column.type==="datetime") || (column.type==="date") ? '' : (column.type==="decimal") ? textFormattingDecimal :  textFormatting}
                  >        
                  {
                    column.type==="decimal" ? 
                      <Format
                            type="fixedPoint"
                            precision={2}
                      /> 
                    : 
                    column.type==="datetime" ? 
                      <Format
                            type="dd/MM/yyyy HH:mm:ss"
                      /> 
                    :
                    column.type==="date" ? 
                      <Format
                            type="dd/MM/yyyy"
                      /> 
                    : ''
                  }        
                  </Column>
            )}                   

            <StateStoring enabled={true} type="localStorage" storageKey="storage" savingTimeout="100"/>
            <Pager allowedPageSizes={this.props.pageSize} showPageSizeSelector={this.props.showPageSizeSelector}/>
            <Paging defaultPageSize={this.props.defaultPageSize} />
          </DataGrid>
          </div>
     );
    }   

    handleDelete(uuid){
      let thisClass=this;

      common.notify.fn_confirm("Sei sicuro di voler eliminare il record selezionato?", () => thisClass.deleteAccess(thisClass, uuid));    
    }

    handlePrint(uuid){
      if(this.prints.length===1){
        let name=this.prints[0]["name"];
        let param=this.prints[0]["param"];
        let fileName=this.prints[0]["fileName"];
        let thisClass=this;

        common.service.postData(thisClass,this.props["print"]["pathname"], {"data":{"fileName":fileName, "name": name, "param":param, "key":uuid}})
          .then(data => {
            common.notify.notifyServerResponse(thisClass, data, "", thisClass.viewReport.bind(this, data.data, fileName));                    
        });

      }           
    }

    viewReport(base64, fileName){
      console.log(base64);
      const link = document.createElement('a');
      link.href = "data:application/pdf;base64," +base64;
      link.target="_blank";
      link.download=fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);      
    }

   
    deleteAccess(thisClass, uuid){  
      common.service.postData(thisClass,'/account/deleteAccess/'+uuid)
          .then(data => {
            common.notify.notifyServerResponse(thisClass, data, "Record eliminato correttamente", thisClass.gridUpdate());                    
      });
    }

}

function  textFormattingDecimal(cellInfo) {
  return "€ "+common.number.roundVal(cellInfo.value, 2);
}

function  textFormatting(cellInfo) {
  return cellInfo.value+'';
}

/*function  dateTimeFormatting(cellInfo) {    
  return common.date.writePreviewDateTimeByVB(cellInfo.value);
}*/

   
function cellRenderUpdate(data) {
  let search=data.column.searchUpdate;  
  let keyField=data.column.dataKey;

  search=search.replace("#KEY#", data.key[keyField]);

  return <NavLink
    to={{pathname:data.column.pathUpdate, search: search}}    
    activeClassName="active"><i className="fa fa-edit orange"></i></NavLink> ;
}
//<img src={icon_update}></img>

function cellRenderDelete(data) {
  let keyField=data.column.dataKey;  

  //return <img src={icon_delete} onClick={() => data.column.clickFunction(data.key[keyField])}></img> ;
  return <i className="fa fa-times red pointer" onClick={() => data.column.clickFunction(data.key[keyField])}></i> ;  
}

function cellRenderPrint(data) {
  let keyField=data.column.dataKey;  
  return <i className="fa fa-print green pointer" onClick={() => data.column.clickFunction(data.key[keyField])}></i> ;  
}


DxGrid.defaultProps = {
    defaultPageSize: 10,    
    allowColumnReordering:false,
    showPageSizeSelector:false,
    allowedPageSizes:[],
    headerFilterVisible:true,
    groupPanelVisible:false,
    columns:[],
};



export default DxGrid;

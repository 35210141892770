import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Card from "components/Card/Card";
import DxGrid from "./DxGrid";

import {common} from 'react_library';

/**
 * Si occupa di renderizzare una card con i seguenti componenti: bottone per l'inserimento e griglia
 */
class PageGrid extends Component {
    controller="";
    functionality="";

    constructor(props) {
        super(props);
        this.controller=props.controller;
        this.functionality=props.functionality;
        this.state={};
     //   console.log(this.dataGrid.current.instance.state(null))
    }

    render(){     
        return (
            <Grid fluid>
              <Row>
                <Col md={12}>
                  <Card
                    title={this.props.title}
                    ctAllIcons
                    content={                        
                      <div>
                        <div className="btnTopGrid">
                          {common.service.checkFunctionality(this.functionality+"_NEW") ?
                              <NavLink
                                    to={{pathname:"/portal/"+this.controller+"/get"}}
                                    className="btn btn-secondary"
                                    activeClassName="active"
                              >Nuovo</NavLink>         
                          :''}
                        </div>                                                   

                        {this.props.data!=null ?
                          <DxGrid 
                            data={this.props.data} 
                            data-key="uuid"
                            gridUpdate = {this.props.eventUpdate}
                            update={{permission: this.functionality+"_UPDATE", 
                                    pathname:"/portal/"+this.controller+"/get",
                                    search: `?uuid=#KEY#`}}
                            print={{permission:this.functionality+"_PRINT",
                                    pathname:"/"+this.controller+"/print"}}       
                                                           
                            >
                            </DxGrid>
                        :''}                                                 
                      </div>
                    }
                  />
                </Col>
              </Row>
            </Grid>
        );
    }     
}

export default PageGrid;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  /**
   * Dato un numero e il numero di decimali si occupa di arrotondarne il valore
   * @param {decimal} val: numero da arrotondare
   * @param {integer} dec: numero di decimali
   * @returns 
   */
  roundVal: function roundVal(val, dec) {
    if (dec == undefined || dec == null) {
      dec = 2;
    }

    return this.formatDecimal(val, dec, ",", ".");
  },

  /**
   * Dato un numero lo arrotonda settando i vari separatori
   * @param {decimal} val: numero da arrotondare
   * @param {integer} c: numero di decimali  
   * @param {string} d: separatore decimali 
   * @param {string} t: separatore migliaia 
   * @returns 
   */
  formatDecimal: function formatDecimal(val, c, d, t) {
    t = t == undefined || t == null ? "" : t;
    var n = val,
        c = isNaN(c = Math.abs(c)) ? 2 : c,
        d = d == undefined ? "," : d,
        t = t == undefined ? "." : t,
        s = n < 0 ? "-" : "",
        i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
  }
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  /**
   * Data una stringa estrae la prima lettera di ciascuna parola
   * @param {array} string:  stringa di input
   */
  getAcronym: function getAcronym(string) {
    var text = '';
    var arr = string.split(' ');

    for (var i = 0; i < arr.length; i++) {
      if (arr.length === 1) {
        text += arr[i].substr(0, 2);
      } else {
        text += arr[i].substr(0, 1);
      }
    }

    return text;
  }
};
exports.default = _default;
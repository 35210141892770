"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _number = _interopRequireDefault(require("./number"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _default = {
  /**
   * Ritorna i giorni della settimana
   */
  getDaysOfWeek: function getDaysOfWeek() {
    return {
      1: "Lunedì",
      2: "Martedì",
      3: "Mercoledì",
      4: "Giovedì",
      5: "Venerdì",
      6: "Sabato",
      7: "Domenica"
    };
  },

  /**
   * Ritorn ai mesi
   */
  getMonths: function getMonths() {
    return {
      1: "Gennaio",
      2: "Febbraio",
      3: "Marzo",
      4: "Aprile",
      5: "Maggio",
      6: "Giugno",
      7: "Luglio",
      8: "Agosto",
      9: "Settembre",
      10: "Ottobre",
      11: "Novembre",
      12: "Dicembre"
    };
  },

  /**
   * Data una data ora in formato db AAAA-MM-DD HH:MM:SS ritorna una data ora formattate per la visualizzazione dd/mm/aaaa HH:MM:SS
   * @param {string} inputDate 
   */
  writePreviewDateTimeByDB: function writePreviewDateTimeByDB(inputDate) {
    if (inputDate !== "" && inputDate != null) {
      inputDate = inputDate.replace("T", " ").replace("+", "");
      var bitsS = inputDate.split(" ")[0].split("-");
      var bitsMM = inputDate.split(" ")[1].split(":");
      var start = new Date(bitsS[0], --bitsS[1], bitsS[2], bitsMM[0], bitsMM[1]);
      return this.writePreviewDateTime(start);
    } else {
      return "";
    }

    ;
  },

  /***
   * Data una data ora in formato db AAAA-MM-DD HH:MM:SS ritorna una data ora formattate per funzionare anche su safari AAAA-MM-DDTHH:MM:SS
   * @param {string} inputDate 
   */
  writeSafariDateTimeByDB: function writeSafariDateTimeByDB(inputDate) {
    if (inputDate !== "" && inputDate != null) {
      inputDate = inputDate.replace(" ", "T");
      return inputDate;
    } else {
      return "";
    }

    ;
  },

  /**
   * Data una data ora in formato db AAAA-MM-DD HH:MM:SS ritorna una data ora 
   * formattate per la visualizzazione in agenda 12 Mar HH:mm
   * @param {string} inputDate 
   */
  writePreviewDateTimeAgendaByDB: function writePreviewDateTimeAgendaByDB(inputDate) {
    if (inputDate !== "" && inputDate != null) {
      inputDate = inputDate.replace("T", " ").replace("+", "");
      var bitsS = inputDate.split(" ")[0].split("-");
      var bitsMM = inputDate.split(" ")[1].split(":");
      var start = new Date(bitsS[0], --bitsS[1], bitsS[2], bitsMM[0], bitsMM[1]);
      return this.writePreviewDateTimeAgenda(start);
    } else {
      return "";
    }

    ;
  },

  /**
   * Data una data in formato db AAAA-MM-DD ritorna una data formattata per la visualizzazione dd/mm/aaaa 
   * @param {string} inputDate 
   */
  writePreviewDateByDB: function writePreviewDateByDB(inputDate) {
    if (inputDate !== "" && inputDate != null) {
      var start = this.writeDateByDB(inputDate);
      return this.writePreviewDate(start);
    } else {
      return "";
    }

    ;
  },

  /**
   * Data una data in formato db AAAA-MM-DD ritorna un DATE
   * @param {string} inputDate 
   */
  writeDateByDB: function writeDateByDB(inputDate) {
    if (inputDate !== "" && inputDate != null) {
      var bitsS = "";
      inputDate = inputDate.replace("T", " ").replace(" +", " ").replace("+", " ");

      if (inputDate.indexOf(" ") > -1) {
        bitsS = inputDate.split(" ")[0].split("-");
      } else {
        bitsS = inputDate.split("-");
      }

      ;
      var start = new Date(bitsS[0], --bitsS[1], bitsS[2]);

      if (!start instanceof Date) {
        return "";
      } else {
        return start;
      }

      ;
    } else {
      return null;
    }

    ;
  },

  /**
   * Data una data in formato data ritorna una data formattata per la visualizzazione dd/mm/aaaa 
   * @param {Date} inputDate 
   */
  writePreviewDate: function writePreviewDate(inputDate) {
    var str = "";
    var input = new Date(inputDate);

    if (!input instanceof Date) {
      return "";
    }

    ;
    var m = input.getMonth() + 1;
    var d = input.getDate();

    if (d < 10) {
      str += "0";
    }

    ;
    str += input.getDate().toString() + "/";

    if (m < 10) {
      str += "0";
    }

    ;
    str += (input.getMonth() + 1).toString() + "/" + input.getFullYear().toString();
    return str;
  },

  /**
   * Data una data in formato data ritorna una data formattata per la visulizzazione dd/mm/aaaa HH:mm:ss
   * @param {Date} inputDate 
   */
  writePreviewDateTime: function writePreviewDateTime(inputDate) {
    var input = new Date(inputDate);

    if (!input instanceof Date) {
      return "";
    }

    ;
    var m = input.getMonth() + 1;
    var d = input.getDate();
    var h = input.getHours();
    var mi = input.getMinutes();
    var str = "";

    if (d < 10) {
      str += "0";
    }

    ;
    str += input.getDate().toString() + "/";

    if (m < 10) {
      str += "0";
    }

    ;
    str += (input.getMonth() + 1).toString() + "/" + input.getFullYear().toString();
    str += " ";

    if (h < 10) {
      str += "0";
    }

    ;
    str += h.toString() + ":";

    if (mi < 10) {
      str += "0";
    }

    ;
    str += mi.toString();
    return str;
  },

  /**
  * Data una data in formato data ritorna una data formattata per la visulizzazione in agenda 12 Mar HH:mm
  * @param {Date} inputDate 
  */
  writePreviewDateTimeAgenda: function writePreviewDateTimeAgenda(inputDate) {
    var input = new Date(inputDate);

    if (!input instanceof Date) {
      return "";
    }

    ;
    var m = input.getMonth() + 1;
    var d = input.getDate();
    var h = input.getHours();
    var mi = input.getMinutes();
    var str = "";

    if (d < 10) {
      str += "0";
    }

    ;
    str += input.getDate().toString() + " ";
    str += this.getMonths()[m];
    str += " ";

    if (h < 10) {
      str += "0";
    }

    ;
    str += h.toString() + ":";

    if (mi < 10) {
      str += "0";
    }

    ;
    str += mi.toString();
    return str;
  },

  /**
  * Data una data ora in formato db AAAA-MM-DD HH:MM:SS ritorna un'orario formattato in HH:MM:SS
  * @param {string} inputDate 
  */
  writePreviewTimeByDB: function writePreviewTimeByDB(inputDate) {
    if (inputDate !== "" && inputDate != null) {
      inputDate = inputDate.replace("T", " ").replace("+", "");
      var bitsS = inputDate.split(" ")[0].split("-");
      var bitsMM = inputDate.split(" ")[1].split(":");
      var start = new Date(bitsS[0], --bitsS[1], bitsS[2], bitsMM[0], bitsMM[1]);
      return this.writePreviewTime(start);
    } else {
      return "";
    }

    ;
  },

  /**
   * Data una data in formato data ritorna l'orario formattato per la visulizzazione HH:mm:ss
   * @param {Date} inputDate 
   */
  writePreviewTime: function writePreviewTime(inputDate) {
    var input = new Date(inputDate);

    if (!input instanceof Date) {
      return "";
    }

    ;
    var m = input.getMonth() + 1;
    var d = input.getDate();
    var h = input.getHours();
    var mi = input.getMinutes();
    var str = "";

    if (h < 10) {
      str += "0";
    }

    ;
    str += h.toString() + ":";

    if (mi < 10) {
      str += "0";
    }

    ;
    str += mi.toString();
    return str;
  },

  /**
   * Data una data in formato data ritorna una data formattata in Lunedì, 29 Gennaio 2021
   * @param {Date} inputDate 
   */
  writePreviewDateString: function writePreviewDateString(inputDate) {
    var str = "";
    var input = new Date(inputDate);

    if (!input instanceof Date) {
      return "";
    }

    ;
    var m = input.getMonth() + 1;
    var d = input.getDate();
    var giorno = this.getDaysOfWeek()[input.getDay()];
    var mese = this.getMonths()[m];
    str += giorno + ", " + d + " " + mese + " " + input.getFullYear().toString();
    return str;
  },

  /**
   * Data una data e ora in formato db ritorna un oggetto javascript Data
   * @param {Date} inputDate : in formato db yyyy-mm-gg HH:mm:ii
   * @param {boolean} seconds : deve contenere i secondi
   * @returns 
   */
  writeDateTimeByDB: function writeDateTimeByDB(inputDate, seconds) {
    if (inputDate != "") {
      inputDate = inputDate.replace("T", " ").replace(" +", " ").replace("+", " ");
      var bitsS = inputDate.split(" ")[0].split("-");
      var bitsMM = inputDate.split(" ")[1].split(":");

      if (seconds) {
        var start = new Date(bitsS[0], --bitsS[1], bitsS[2], bitsMM[0], bitsMM[1], bitsMM[2]);
        return start;
      } else {
        var start = new Date(bitsS[0], --bitsS[1], bitsS[2], bitsMM[0], bitsMM[1]);
        return start;
      }
    } else {
      return null;
    }

    ;
  },

  /**
   * Ritorna la differenza tra due date in un tipo definito
   * @param {Date} date1: data iniziale in formato oggetto data di javascript
   * @param {Date} date2 : data fiale in formato oggetto data di javascript
   * @param {String} type : tipologia di ritorno (sec = secondi, min = minuti, H=ore, G=giorni, S=settimane, M=mesi, A= anni, VUOTO= millisecondi)
   * @returns 
   */
  timeBetween: function timeBetween(date1, date2, type) {
    // Convert both dates to milliseconds
    var date1_ms = date1.getTime();
    var date2_ms = date2.getTime(); // Calculate the difference in milliseconds

    var difference_ms = Math.abs(date1_ms - date2_ms); // The number of milliseconds in one:
    // M

    var ONE_TIME = 1000 * 60;

    if (type == "sec") {
      ONE_TIME = 1000;
    }

    if (type == "min") {} else if (type == "H") {
      ONE_TIME = ONE_TIME * 60;
    } else if (type == "G") {
      ONE_TIME = ONE_TIME * 60;
      ONE_TIME = ONE_TIME * 24;
    } else if (type == "S") {
      ONE_TIME = ONE_TIME * 60;
      ONE_TIME = ONE_TIME * 24;
      ONE_TIME = ONE_TIME * 7;
    } else if (type == "M") {
      var months;
      months = (date1.getFullYear() - date2.getFullYear()) * 12;
      months -= date2.getMonth() + 1;
      months += date1.getMonth();
      return (months <= 0 ? 0 : months) + 1;
    } else if (type == "A") {
      ONE_TIME = ONE_TIME * 60;
      ONE_TIME = ONE_TIME * 24;
      ONE_TIME = ONE_TIME * 365;
    } else if (type == null || type == "") {
      ONE_TIME = difference_ms;
    }

    ; // Convert back to days and return

    if (type == "H") {
      return difference_ms / ONE_TIME;
    } else {
      return Math.round(Math.ceil(difference_ms / ONE_TIME));
    }
  },

  /**
   * Dato un numero di secondi formatta in (8 giorni 5 ore 3 minuti oppure 8G 5H 3M)
   * @param {integer} seconds: numero di secondi 
   * @param {boolean} min: deve tornare l'ora abbreviata o no? minuto => M , ora=>H, giorno=>G
   * @returns 
   */
  secondsToTempo: function secondsToTempo(seconds, min) {
    var sec_num = parseInt(seconds, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;
    var days = Math.floor(hours / 24);
    if (days >= 1) hours = Math.floor(hours - days * 24);

    if (min == true) {
      var strMinutes = "M";
    } else {
      if (minutes == 1) var strMinutes = "minuto";else var strMinutes = "minuti";
    }

    if (min == true) {
      var strOre = "H";
    } else {
      if (hours == 1) var strOre = "ora";else var strOre = "ore";
    }

    if (min == true) {
      var strDays = "G";
    } else {
      if (days == 1) var strDays = "giorno";else var strDays = "giorni";
    }

    var time = "";
    if (days > 0) time = time + days + ' ' + strDays + ' ';

    if (hours > 0) {
      time = time + hours + ' ' + strOre + ' ';
    }

    if (minutes > 0) {
      time = time + minutes + ' ' + strMinutes;
    }

    return time;
  }
};
exports.default = _default;